import logo from './logo.svg';
import './App.scss';
import LayoutComp from './Layout';

function App() {
  return (
    <div className="App">
     <LayoutComp/>
    </div>
  );
}

export default App;
