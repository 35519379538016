import React, { useEffect } from 'react'
import playListPic from './../../Assets/Images/playListPic.png'
import './AccountOverview.scss'
import { EditIcon } from '../../Assets/Icons'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { imageLink } from '../../Constant/Utils'
function AccountOverview() {
    const auth0User = useSelector(e => e.authenticationSlice.auth0User)
    const user = useSelector(e => e.authenticationSlice.user)
    const navigate = useNavigate()

    return (
        <div className='AccountOverview'>
            <div className='innerAccountOverview'>
                <div className='userNameDiv'>
                    <div className='userName'>
                        <div className='dp'>
                            <img  src={imageLink(user?.image || auth0User?.picture)}  />
                        </div>
                        <div className='nameEmail'>
                            <h4>{user?.name}</h4>
                            <p>{auth0User?.email}</p>
                        </div>
                    </div>
                    <button className='editIcon' onClick={() => {
                        navigate(`/dashboard/complete-profile?edit=true`)
                    }}>
                        <EditIcon />
                    </button>
                </div>
                <hr />
                <div className='textBox'>
                    <h4>Bio</h4>
                    <p>{user?.bio}</p>
                </div>
                <hr />
                <div className='textBox'>
                    <h4>My universe</h4>
                    <p>{user?.musicgenre?.join(', ')}</p>
                    <h4 className='charMy'>My characteristics</h4>
                    <p>{user?.musictype?.join(', ')}</p>
                </div>
                <hr />
                <div className='textBox'>
                    <h4>Social network</h4>
                    {user?.additional_links?.map((a, i) => (
                        <p>{a}</p>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AccountOverview