import React, { useEffect, useRef, useState } from 'react'
import './setBudget.scss'
import { EditIcon } from '../../../Assets/Icons'
import { DatePicker, Popover } from 'antd'
import { calculateStreams, calculateStreamsAtPrice, calculateStreamsForPrice, getParams } from '../../../Constant/Utils'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import Budget from '../../../Components/Budget/Budget'
function SetBudget() {
    let [data, setData] = useState({})
    const [budgetNumber, setBudgetNumber] = useState(50)
    const [budgetNumberPercent, setBudgetNumberPercent] = useState(0)
    const navigate = useNavigate()
    const [editButton, setEditButton] = useState(false)
    useEffect(() => {
        let param = getParams('data')
        console.log(param, 'param')
        setData(param)
        setBudgetNumberPercent((Number(param?.budget) / (10000 - 50)) * 100)
    }, [])
    const next = () => {
        localStorage.setItem('campaign-data', JSON.stringify(data))
        navigate(`/create-campaign/review-campaign`)
    }
    const back = () => {
        localStorage.setItem('campaign-data', JSON.stringify(data))
        navigate(`/create-campaign/add-track`)
    }

    const validate = () => {
        let obj = { ...data }
        if (obj.campaign_type &&
            obj.budget &&
            obj.start_date) {
            return true
        }

    }


    const spanRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (spanRef.current && inputRef.current) {
            // Adjust input width based on the span width plus some padding
            inputRef.current.style.width = `${spanRef.current.offsetWidth + 10}px`;
        }
    }, [data.budget]); // Runs whenever the budget changes

    return (
        <div className='SetBudget'>
            <div className='innerSetBudget'>
                <div className='budgetRange'>
                    <div className='duration'>
                        <Popover overlayClassName='newStylePopup' content={() => {
                            return (
                                <p>Coming Soon</p>
                            )
                        }}>
                            <div style={{ cursor: 'not-allowed' }} className={data?.campaign_type === 'per month' ? 'durationTab active' : 'durationTab'} onClick={() => {
                                // let obj = { ...data }
                                // obj.campaign_type = 'per month'
                                // console.log(obj)
                                // setData(obj)
                            }}>Per month</div>
                        </Popover>
                        <div className={data?.campaign_type === 'one time' ? 'durationTab active' : 'durationTab'} onClick={() => {
                            let obj = { ...data }
                            obj.campaign_type = 'one time'
                            console.log(obj)
                            setData(obj)
                        }}>One time</div>
                    </div>
                    {/* <div className='budgetDiv'> */}
                    <div className='amountRage'>
                        <div className='amount'>
                            <h3>
                                ${" "}
                                {editButton ? (
                                    <input
                                        ref={inputRef}
                                        type="number"
                                        value={data?.budget}
                                        min={50}
                                        max={10000}
                                        autoFocus
                                        onChange={(ev) => {
                                            // if (ev.target.value) {
                                                // setBudgetNumber(ev.target.value)
                                                setBudgetNumberPercent((ev.target.value / (10000 - 50)) * 100)

                                                setData({ ...data, budget: ev.target.value });
                                            // } else {
                                            //     setData({ ...data, budget: 50 });
                                            // }
                                        }}
                                        onBlur={() => {
                                            if (data?.budget < 50) {
                                                // setBudgetNumber(50)
                                                setBudgetNumberPercent((50 / (10000 - 50)) * 100)
                                                setData({ ...data, budget: 50 });
                                            }
                                            setEditButton(false)
                                        }} // Close edit mode when input loses focus
                                    />
                                ) : (
                                    <>
                                        {data?.budget || 50}
                                        <sup onClick={() => setEditButton(true)} style={{ cursor: 'pointer' }}>
                                            <EditIcon />
                                        </sup>
                                    </>
                                )}
                                {/* Hidden span to calculate width based on content */}
                                <span
                                    ref={spanRef}
                                    style={{
                                        position: 'absolute',
                                        visibility: 'hidden',
                                        whiteSpace: 'pre',
                                    }}
                                >
                                    {data?.budget}
                                </span>
                            </h3>
                            <p>{calculateStreamsForPrice(Number(data?.budget)).text}</p>
                        </div>
                        <div className='rangeSelector'>
                            <span>$50</span>
                            <div className='rageSelectStepDiv'>
                                {Array.from({ length: 21 }, (_, index) => {
                                    let number = 0
                                    return (
                                        <span key={index} className='rageSelectStep'
                                            onClick={() => {
                                                let obj = { ...data }
                                                obj.budget = 500 * (index)
                                                setBudgetNumberPercent((500 * (index) / (10000 - 50)) * 100)
                                                setData(obj)
                                            }}
                                        ></span>
                                    )
                                })}
                                <div className='progressDiv'>
                                    <div className='Progress' style={{ width: `${Math.floor(budgetNumberPercent)}%` }}></div>
                                </div>
                                <input type='range' min={50} max={10000} step={50} value={data?.budget || 50} onChange={(ev) => {
                                    // console.log(ev)
                                    // setBudgetNumber(ev.target.value)
                                    setBudgetNumberPercent((ev.target.value / (10000 - 50)) * 100)
                                    let obj = { ...data }
                                    obj.budget = String(ev.target.value)
                                    setData(obj)
                                }} />
                            </div>
                            <span>$10,000</span>
                        </div>
                        <div className='tierDiv'>
                            <div className='currentTier'>
                                <h3>Current Tier</h3>
                                <p className='estimateStreamTier'>{calculateStreamsForPrice(Number(data?.budget)).text}</p>
                            </div>
                            <div className='nextTier'
                            // onClick={() => {
                            //     setBudgetNumber(Number(budgetNumber) + 50)
                            //     setBudgetNumberPercent(((budgetNumber + 50) / (10000 - 50)) * 100)
                            // }}
                            >
                                <h3>Next Tier</h3>
                                <p className='estimateStreamTier'>{calculateStreamsForPrice(Number(data?.budget) + 50).text}</p>
                                <p className='increaseText'>Increase your budget by $50 and get {calculateStreamsForPrice(Number(data?.budget) + 50).sumOfStreams} additional streams</p>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                    {/* <div className='startDate'>
                        <span>Start date</span>
                        <DatePicker
                            removeIcon={true}
                            format={'DD-MM-YYYY'}
                            value={dayjs(data?.start_date || new Date())}
                            disabledDate={(current) => current && current < dayjs().startOf('day')} // Disable past dates
                            onChange={(ev) => {
                                console.log(ev.toISOString());

                                let obj = { ...data };
                                if (ev) {
                                    obj.start_date = ev.toISOString();
                                    setData(obj);
                                }
                            }}
                        />
                        <p className='campaignTime'>Your campaign will run for 5 months</p>
                    </div> */}
                </div>
            </div>
            <div className='stepsDiv'>
                <p onClick={back}>Previous step</p>
                <button onClick={next} disabled={!validate()}>Next step</button>
            </div>
        </div>
    )
}

export default SetBudget