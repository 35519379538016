import React from 'react'
import LandingNavbar from '../../Components/LandingNavbar/LandingNavbar'
import './landingpage.scss'
import calenderIcon from './../../Assets/Images/calender.png'
import musicIcon from './../../Assets/Images/music.png'
import userIcon from './../../Assets/Images/users.png'
import musicCalenderIcon from './../../Assets/Images/musicCalender.png'
import scheduleIcon from './../../Assets/Images/schedule.png'
import searchUrlIcon from './../../Assets/Images/searchUrl.png'
import insightIcon from './../../Assets/Images/insight.png'
import Footer from '../../Components/Footer/Footer'
import Budget from '../../Components/Budget/Budget'
import { useNavigate } from 'react-router-dom'

function LandingPage() {
    const navigate = useNavigate()

    const handleFormSubmit = async () => {
        const domain = "dev-2z4p074m.us.auth0.com";
        const client_id = process.env.REACT_APP_AUTH0;
        const audience = "https://dev-2z4p074m.us.auth0.com/api/v2/";
        const scope = "openid profile email";
        const response_type = "token";
        const redirect_uri = `${window.location.origin}/callback`;
        window.location.replace(
            `https://${domain}/authorize?` +
            `audience=${audience}&` +
            `scope=${scope}&` +
            `response_type=${response_type}&` +
            `client_id=${client_id}&` +
            `redirect_uri=${redirect_uri}`
        );
    };
    return (
        <div className='LandingPageBody'>
            <LandingNavbar />
            <div className='LandingPage'>
                <div className='innerLandingPage'>
                    <div className='heroSection' id="heroSection">
                        <div className='heroSectionText'>
                            <h1>
                                Get your song<br />placed!
                            </h1>
                            <h3 className='subText'>
                                The best organic playlist campaigns in the music industry
                            </h3>
                            <p>
                                Join Songplace and supercharge your music career! Get organic playlist placements (bot-free), boost your streams, and catch the attention of Spotify’s algorithm for potential editorial features—all while growing a loyal fanbase.
                            </p>
                            <div className='startCampaignSignup'>
                                <button className='campaign' onClick={() => {
                                    navigate('/create-campaign/add-track')
                                }}>Start a campaign</button>
                                <button className='signUp' onClick={handleFormSubmit}>Sign up</button>
                            </div>
                        </div>
                        <div className='heroImage'></div>
                        <div className='bgGrad'></div>
                    </div>
                    <div className='aboutUsSection' id="aboutUsSection">
                        <div className='bgGrad'></div>
                        <div className='innerAboutUsSection'>
                            <div className='aboutUsContent'>
                                <h3>About Us</h3>
                                <p>Songplace is the best platform to professionally manage your Spotify and Apple Music playlists. Whether you are a record label, an artist that manages playlists or a professional curator, this is the must-have tool for you. No longer will you have to waste countless hours of checking songs in your playlists, setting reminders to place or delete them, be busy on release day, and keep track of your playlists. With Songplace as the number one playlist management tool this will be all done for you!</p>
                                <p>Would you like to make an inquiry, or do you have any feedback or remarks? Don’t hesitate to reach out below:</p>
                                <button className='campaignStartBtn' onClick={() => {
                                    navigate('/create-campaign/add-track')
                                }}>Start a campaign</button>
                            </div>
                        </div>
                    </div>
                    <div className='featuresSection' id="featuresSection">
                        <div className='innerFeaturesSection'>
                            <h3>Features</h3>
                            <p className='headingText'>Rmet facilisi arcu odio urna aenean erat. Pellentesque in vitae lobortis orci <br />tincidunt facilisis. Pulvinar lacus ultricies turpis urna sapien.</p>
                            <div className='featuresDiv'>
                                <div className='featDiv'>
                                    <img src={calenderIcon} />
                                    <p>
                                        SCHEDULE START AND END DATES FOR SONG PLACEMENTS
                                    </p>
                                </div>
                                <div className='featDiv'>
                                    <img src={musicIcon} />
                                    <p>
                                        USE SPOTIFY AND APPLE MUSIC
                                    </p>
                                </div>
                                <div className='featDiv'>
                                    <img src={userIcon} />
                                    <p>
                                        USE MULTIPLE PROFILES AT THE SAME TIME
                                    </p>
                                </div>
                                <div className='featDiv'>
                                    <img src={musicCalenderIcon} />
                                    <p>
                                        SCHEDULE UNRELEASED MUSIC
                                    </p>
                                </div>
                                <div className='featDiv'>
                                    <img src={scheduleIcon} />
                                    <p>
                                        ADD NOTES TO SONGS IN PLAYLISTS
                                    </p>
                                </div>
                                <div className='featDiv'>
                                    <img src={searchUrlIcon} />
                                    <p>
                                        SEARCH FOR SONG BY TYPING OR PASTING URLS
                                    </p>
                                </div>
                                <div className='featDiv'>
                                    <img src={insightIcon} />
                                    <p>
                                        ANALYTICS: SHOW SONG POPULARITY SCORE
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='budgetSection' id="budgetSection">
                        <div className='bgGrad last'></div>
                        <div className='innerBudgetSection'>
                            <h3>Customized Budgeting</h3>
                            <p>Rmet facilisi arcu odio urna aenean erat. Pellentesque in vitae lobortis orci<br />tincidunt facilisis. Pulvinar lacus ultricies turpis urna sapien.</p>
                            <Budget />
                        </div>
                    </div>
                    <div className='newsLetterSection' id="newsLetterSection">
                        <div className='innerNewsLetterSection'>
                            <h3>Join Our Newsletter</h3>
                            <p>Rmet facilisi arcu odio urna aenean erat. Pellentesque in vitae lobortis<br /> orci tincidunt facilisis. Pulvinar lacus ultricies turpis urna sapien.</p>
                            <div className='newsLetterInput'>
                                <input placeholder='Enter your email address' />
                                <button className='submitNewsLetter'>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default LandingPage