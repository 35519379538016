import React from 'react'

function Spinner({
  className
}) {
  return (
    <span className={`loader ${className}`}></span>
  )
}

export default Spinner