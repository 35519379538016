import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function Authentication({ children }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [pathName, setPathName] = useState(window.location.pathname)
    useEffect(() => {
        // if (pathName.toLowerCase() != '/dashboard/profile') {
        //     if (userProfile  && !userProfile?.profile?.fullname) {
        //         navigate('/dashboard/Profile?tab=General')
        //     }
        // }
    }, [])


    return (
        <>

            {children}
        </>
    )
}

export default Authentication