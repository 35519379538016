import { configureStore } from '@reduxjs/toolkit'
import todoSlice from './features/todoSlice'
import authenticationSlice from './features/Authentication'
import artistSlice from './features/Artist'
import spotifySlice from './features/Spotify'

export const store = configureStore({
    reducer: {
        authenticationSlice: authenticationSlice,
        spotifySlice: spotifySlice,
        artistSlice: artistSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})