import React, { useEffect, useState } from "react";
import { EditIcon } from "../../../Assets/Icons";
import "./reviewCampaign.scss";
import { calculateStreamsForPrice, extractId, getParams } from "../../../Constant/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSongDetail } from "../../../module/features/Spotify";
import { addArtistCampaigns, getArtistCampaigns, getNotfication } from "../../../module/features/Artist";
import questionMark from "./../../../Assets/Images/question-mark.jpg";
import Spinner from "../../../Constant/Spinner";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { toast } from "react-toastify";
import CampaignSuccess from "./CampaignSuccess";
function ReviewCampaign() {
  let [data, setData] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const track = useSelector((e) => e.spotifySlice.track);
  const user = useSelector(e => e.authenticationSlice.user)
  const auth0User = useSelector(e => e.authenticationSlice.auth0User)
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    navigate("/dashboard/view-campaigns");
  };

  const handleCancel = () => {
    // setIsModalOpen(false);
    // navigate("/dashboard/view-campaigns");
  };


  useEffect(() => {
    let param = getParams("data");
    console.log(param, "param");
    setData(param);
    dispatch(
      getSongDetail({
        q: extractId(param?.track_link),
      })
    );
  }, []);
  const [loaderCampaign, setLoaderCampaign] = useState(false)
  useEffect(() => {
    console.log(user, 'useruser')
    if (data && auth0User && user) {
      const type = queryParams.get("type")
      if (type === "cancel") {
        toast.error("cancel payment");
      } else if (type === "success") {
        setLoaderCampaign(true)
        showModal()
        console.log(data, 'datadata')
        dispatch(addArtistCampaigns({
          data: {
            ...data,
            artist_email: auth0User?.email,
            userId: user?.userId
          }, callback
        }));
      }
    }
  }, [data, auth0User, user])

  const callback = () => {
    setLoader(true);
    localStorage.removeItem("campaign-data");
    localStorage.setItem('notification', "true")
    dispatch(getNotfication())
    dispatch(getArtistCampaigns())
    setLoaderCampaign(false)
  };

  const addArtistCampaignsFn = async () => {
    console.log(user, 'useruser')
    if (Object.keys(user).length === 0) {
      const domain = "dev-2z4p074m.us.auth0.com";
      const client_id = process.env.REACT_APP_AUTH0;
      const audience = "https://dev-2z4p074m.us.auth0.com/api/v2/";
      const scope = "openid profile email";
      const response_type = "token";
      const budget = data?.budget;
      const redirect_uri = `${window.location.origin}/callback?budget=${budget}`;
      window.location.replace(
        `https://${domain}/authorize?` +
        `audience=${audience}&` +
        `scope=${scope}&` +
        `response_type=${response_type}&` +
        `client_id=${client_id}&` +
        `redirect_uri=${redirect_uri}`
      );
    } else {
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PRIVATE_TEST);
      setLoader(true);
      console.log("runnn");
      const budget = data?.budget; // retrieve the already set budget
      const stripeSession = await createStripeSession(budget);
      stripe.redirectToCheckout({ sessionId: stripeSession.sessionId });
    }
  };

  const createStripeSession = async (budget) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URI}/artistCampaign/create-stripe-session`,
      {
        budget,
      }
    );
    return response.data;
  };

  const back = () => {
    localStorage.setItem("campaign-data", JSON.stringify(data));
    navigate(`/create-campaign/set-budget-duration`);
  };

  return (
    <div className="ReviewCampaign">
      <div className="innerReviewCampaign">
        <div className="trackDetail">
          <div className="innerTrackDetail">
            <div className="heading">
              <div>
                <h3>Spotify song link</h3>
                <a href={data?.spotify_link}>
                  <p>{data?.spotify_link}</p>
                </a>
              </div>
              <button
                className="editButon"
                onClick={() => {
                  navigate(`/create-campaign/add-track`);
                }}
              >
                <EditIcon />
              </button>
            </div>
            <div className="spotifyLink">
              <div className="songDetail">
                <div className="imageName">
                  <img src={track?.album?.images[0]?.url || questionMark} />
                  <div className="songNameArtist">
                    <p>{track?.name || data?.track_name}</p>
                    <p>{track?.artists[0]?.name || data?.artist_name}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lyricDiv">
              <div className="innerLyricDiv">
                <h3>Lyrics of your track</h3>
                <p>{data?.track_lyrics?.join(", ")}</p>
              </div>
            </div>
            <div className="dateSchedule">
              <div className="innerDateSchedule">
                <div className="date">
                  <p className="dateLabel">Start date</p>
                  <p className="dateTime">
                    {new Date(data?.start_date).toLocaleDateString()}
                  </p>
                </div>
              </div>
            </div>
            <div className="budgetDiv">
              <div className="heading">
                <h3>Campaign budget</h3>
                <h2>
                  ${data?.budget}{" "}
                  <span>{calculateStreamsForPrice(Number(data?.budget)).text}</span>
                </h2>
              </div>
              <button
                className="editButon"
                onClick={() => {
                  navigate(`/create-campaign/set-budget-duration`);
                }}
              >
                <EditIcon />
              </button>
            </div>
            <div className="note">
              <p>Note: By submitting this campaign, your request will be sent to one of our representative and you will be contacted via email accordingly.
                In case of any questions or concerns, feel free to reach us out at support@songplace.io</p>
            </div>
          </div>
        </div>
      </div>
      <div className="stepsDiv">
        <p onClick={back}>Previous step</p>
        <button onClick={addArtistCampaignsFn}>
          {loader ? (
            <>
              <Spinner />
            </>
          ) : (
            `Submit`
          )}
        </button>
      </div>
      <CampaignSuccess isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        loaderCampaign={loaderCampaign}
      />
    </div>
  );
}

export default ReviewCampaign;
