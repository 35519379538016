import React from 'react'
import './campaignSuccess.scss'
import { Modal } from 'antd'
import successImage from './../../../Assets/Images/undraw_completing_re_i7ap.png'
import Spinner from '../../../Constant/Spinner'

function CampaignSuccess({ isModalOpen,
    handleOk,
    handleCancel,
    loaderCampaign }) {
    return (
        <Modal
            className="SubscriptionModal" closable={false} footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div className="innerSubscriptionModal">
                {loaderCampaign ?
                    <>
                        <Spinner className="campaignLoader" />
                        <h3>Proceeding your campaign</h3>
                    </>
                    :
                    <>
                        <img alt="success-img" className="SuccessSubsImage" src={successImage} />
                        <h3>Campaign successfully submitted! </h3>
                        <p>Thank you for choosing Songplace. Our team will get back to you shortly</p>
                        <button className="SubscribeBtn" onClick={handleOk}>Go to dashboard</button>
                    </>
                }
            </div>
        </Modal>
    )
}

export default CampaignSuccess