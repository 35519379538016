import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import axios from 'axios'
import { cookieManager } from '../../Constant/Utils';

const initialState = {
    token: '',
    track: null,
    artist: null,
    artist_album: null,
    artist_top_track: []
}

export const getToken = createAsyncThunk(
    "spotifySlice/getToken",  // Changed from "spotifySlice/getSongDetail"
    async (data) => {
        try {
            let token = cookieManager.getCookie('token')
            if (token) {
                return token
            } else {
                const client_id = process.env.REACT_APP_CLIENT_ID;
                const client_secret = process.env.REACT_APP_CLIENT_SECRET;

                const tokenUrl = 'https://accounts.spotify.com/api/token';
                const authHeader = 'Basic ' + btoa(`${client_id}:${client_secret}`);

                try {
                    const response = await axios.post(
                        tokenUrl,
                        'grant_type=client_credentials',
                        {
                            headers: {
                                'Authorization': authHeader,
                                'Content-Type': 'application/x-www-form-urlencoded',
                            },
                        }
                    );

                    const token = response.data?.access_token;
                    cookieManager.setCookie("token", token, 60);
                    return token;
                } catch (error) {
                    console.error('Error fetching token:', error);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
);

export const getSongDetail = createAsyncThunk(
    "spotifySlice/getSongDetail",
    async (data) => {
        try {
            let token = cookieManager.getCookie('token');
            console.log(token, 'tokentoken');

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://api.spotify.com/v1/tracks/${data?.q}?type=track%2Cartist`,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            };

            try {
                const response = await axios.request(config);
                return {
                    data: response.data,  // Returning the response data here
                };
            } catch (error) {
                console.log(error);
                // Optionally, you could handle or return an error object here if needed
            }


        } catch (error) {
            console.error(error);
        }
    });


export const getArtist = createAsyncThunk(
    "spotifySlice/getArtist",
    async (data) => {
        try {
            let token = cookieManager.getCookie('token');
            console.log(token, 'tokentoken');
            // https://open.spotify.com/artist/74KM79TiuVKeVCqs8QtB0B
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://api.spotify.com/v1/artists/${data?.id}`,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            };

            try {
                const response = await axios.request(config);
                console.log(response?.data, '.data.data')
                return {
                    data: response.data,  // Returning the response data here
                };
            } catch (error) {
                console.log(error);
                // Optionally, you could handle or return an error object here if needed
            }


        } catch (error) {
            console.error(error);
        }
    });


export const getArtistAlbum = createAsyncThunk(
    "spotifySlice/getArtistAlbum",
    async (data) => {
        try {
            let token = cookieManager.getCookie('token');
            console.log(token, 'tokentoken');
            // https://open.spotify.com/artist/74KM79TiuVKeVCqs8QtB0B
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://api.spotify.com/v1/artists/${data.id}/albums`,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            };

            try {
                const response = await axios.request(config);
                console.log(response?.data, '.data.data')
                return {
                    data: response.data,  // Returning the response data here
                };
            } catch (error) {
                console.log(error);
                // Optionally, you could handle or return an error object here if needed
            }


        } catch (error) {
            console.error(error);
        }
    });


export const getArtistTopTrack = createAsyncThunk(
    "spotifySlice/getArtistTopTrack",
    async (data) => {
        try {
            let token = cookieManager.getCookie('token');
            console.log(token, 'tokentoken');
            // https://open.spotify.com/artist/74KM79TiuVKeVCqs8QtB0B
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://api.spotify.com/v1/artists/${data.id}/top-tracks`,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            };

            try {
                const response = await axios.request(config);
                console.log(response?.data, '.data.data')
                return {
                    data: response.data,  // Returning the response data here
                };
            } catch (error) {
                console.log(error);
                // Optionally, you could handle or return an error object here if needed
            }


        } catch (error) {
            console.error(error);
        }
    });

export const spotifySlice = createSlice({
    name: 'spotifySlice',
    initialState,
    reducers: {
        addTodo: (state, action) => {
            // request
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getToken.pending, (state) => {
            })
            .addCase(getToken.fulfilled, (state, action) => {
                state.token = action.payload;
            })
            .addCase(getToken.rejected, (state) => {
            })
            .addCase(getSongDetail.pending, (state) => {
            })
            .addCase(getSongDetail.fulfilled, (state, action) => {
                // console.log(action.payload, 'tracktracktrack')
                state.track = action?.payload?.data;
            })
            .addCase(getSongDetail.rejected, (state) => {
            })
            .addCase(getArtist.pending, (state) => {
            })
            .addCase(getArtist.fulfilled, (state, action) => {
                console.log(action?.payload?.data, 'artistartist')
                state.artist = action?.payload?.data;
            })
            .addCase(getArtist.rejected, (state) => {
            })
            .addCase(getArtistAlbum.pending, (state) => {
            })
            .addCase(getArtistAlbum.fulfilled, (state, action) => {
                console.log(action?.payload?.data, 'artistartist')
                state.artist_album = action?.payload?.data?.items;
            })
            .addCase(getArtistAlbum.rejected, (state) => {
            })
            .addCase(getArtistTopTrack.pending, (state) => {
            })
            .addCase(getArtistTopTrack.fulfilled, (state, action) => {
                console.log(action?.payload?.data, 'artistartist')
                state.artist_top_track = action?.payload?.data?.tracks;
            })
            .addCase(getArtistTopTrack.rejected, (state) => {
            })
    }
})

export const { addTodo } = spotifySlice.actions

export default spotifySlice.reducer