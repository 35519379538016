import React, { useState } from "react";
import './footer.scss'
import SongplaceLogo from './../../Assets/Images/songplace.png'
import spotifyicon from './../../Assets/Images/Spotify.png'
import intagramicon from './../../Assets/Images/Instagram.png'
import youtubeicon from './../../Assets/Images/Youtube.png'

const Footer = ({ handleAdd }) => {
  const [email, setEmail] = useState("");
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  return (
    <div className="footer-container">
      <div className="footer-main">
        <div className="footer-section1">
          <img
            className="SideStyleImage"
            src={SongplaceLogo}
            alt="footer songplace"
          />
          <p className="footer-para">
            Take your playlist management and curation to the next level.
          </p>
          <div className="socialIconsDiv">
            <img alt="spotifyicon.svg" src={spotifyicon} className="footer-icon" width="41" height="42" />
            <img alt="intagramicon.svg" src={intagramicon} className="footer-icon" width="41" height="42" />
            <img alt="youtubeicon.svg" src={youtubeicon} className="footer-icon" width="41" height="42" />
          </div>
        </div>
        <div className="footer-section2">
          <div className="footer-input-container">
            <p>Subscribe to our newsletter</p>
            <form
              action="https://spotplace.us14.list-manage.com/subscribe/post?u=5d845dc9bdd50ddec46ce928b&amp;id=9fe6ded621"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              target="_blank"
            >
              <input
                placeholder="Enter your email"
                type="email"
                value={email}
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                onChange={handleEmailChange}
                required
                style={{ color: "white" }}
              />
              <button
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer-bottom">
        <div className="section3">
          <p
            className="bottom-para"
            style={{ cursor: "pointer" }}
            onClick={() => handleAdd("condition")}
          >
            Terms & conditions
          </p>
          <p
            className="bottom-para"
            style={{ cursor: "pointer" }}
            onClick={() => handleAdd("policy")}
          >
            Privacy policy
          </p>
        </div>
        <div className="section4">
          <p className="bottom-para">
            All rights reserved © Songplace - {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
