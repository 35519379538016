import React, { useEffect, useRef, useState } from 'react'
import './budget.scss'
import { EditIcon } from '../../Assets/Icons'
import { calculateStreamsAtPrice, calculateStreamsForPrice, getParams } from '../../Constant/Utils'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
function Budget() {
    let [data, setData] = useState({})
    const [budgetNumber, setBudgetNumber] = useState(50)
    const [budgetNumberPercent, setBudgetNumberPercent] = useState(0)
    const navigate = useNavigate()
    const [editButton, setEditButton] = useState(false)
    useEffect(() => {
        let param = getParams('data')
        console.log(param, 'param')
        setData(param)
    }, [])
    const next = () => {
        localStorage.setItem('campaign-data', JSON.stringify(data))
        navigate(`/create-campaign/review-campaign`)
    }



    const spanRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (spanRef.current && inputRef.current) {
            // Adjust input width based on the span width plus some padding
            inputRef.current.style.width = `${spanRef.current.offsetWidth + 10}px`;
        }
    }, [budgetNumber]); // Runs whenever the budget changes

    return (
        <div className='Budget'>
            <div className='innerBudget'>
                <div className='amountRage'>
                    <div className='amount'>
                        <h3>
                            ${" "}
                            {editButton ? (
                                <input
                                    ref={inputRef}
                                    type="number"
                                    value={budgetNumber}
                                    min={50}
                                    max={10000}
                                    autoFocus
                                    onChange={(ev) => {
                                        // if(ev.target.value){
                                        setBudgetNumber(ev.target.value)
                                        setBudgetNumberPercent((ev.target.value / (10000 - 50)) * 100)

                                        // setData({ ...data, budget: ev.target.value });
                                        // }else{
                                        //     setData({ ...data, budget: 50 });
                                        // }
                                    }}
                                    onBlur={() => {
                                        if (budgetNumber < 50) {
                                            setBudgetNumber(50)
                                            setBudgetNumberPercent((50 / (10000 - 50)) * 100)
                                            // setData({ ...data, budget: 50 });
                                        }
                                        setEditButton(false)
                                    }} // Close edit mode when input loses focus
                                />
                            ) : (
                                <>
                                    {budgetNumber || 50}
                                    <sup onClick={() => setEditButton(true)} style={{ cursor: 'pointer' }}>
                                        <EditIcon />
                                    </sup>
                                </>
                            )}
                            {/* Hidden span to calculate width based on content */}
                            <span
                                ref={spanRef}
                                style={{
                                    position: 'absolute',
                                    visibility: 'hidden',
                                    whiteSpace: 'pre',
                                }}
                            >
                                {budgetNumber}
                            </span>
                        </h3>
                        <p>{calculateStreamsForPrice(Number(budgetNumber)).text}</p>
                    </div>
                    <div className='rangeSelector'>
                        <span>$50</span>
                        <div className='rageSelectStepDiv'>
                            {Array.from({ length: 21 }, (_, index) => {
                                let number = 0
                                return (
                                    <span key={index} className='rageSelectStep'
                                        onClick={() => {
                                            setBudgetNumberPercent((500 * (index) / (10000 - 50)) * 100)
                                            setBudgetNumber(500 * (index))
                                        }}
                                    ></span>
                                )
                            })}
                            <div className='progressDiv'>
                                <div className='Progress' style={{ width: `${Math.floor(budgetNumberPercent)}%` }}></div>
                            </div>
                            <input type='range' min={50} max={10000} step={50} value={budgetNumber || 50} onChange={(ev) => {
                                console.log(ev)
                                setBudgetNumber(ev.target.value)
                                setBudgetNumberPercent((ev.target.value / (10000 - 50)) * 100)
                                // let obj = { ...data }
                                // obj.budget = String(ev.target.value)
                                // setData(obj)
                            }} />
                        </div>
                        <span>$10,000</span>
                    </div>
                    <div className='tierDiv'>
                        <div className='currentTier'>
                            <div className='tierHover'>
                                <button className='campaign' onClick={() => {
                                    let obj = {
                                        track_name: "",
                                        artist_name: "",
                                        track_link: "",
                                        spotify_link: "",
                                        spotify_uri: "",
                                        track_released: true,
                                        track_lyrics: [],
                                        budget: Number(budgetNumber),
                                        start_date: dayjs(new Date()).toISOString(),
                                        end_date: "",
                                        campaign_type: "one time",
                                        track_image: "",
                                        userId: "",
                                    }
                                    localStorage.setItem("campaign-data", JSON.stringify(obj));
                                    navigate(`/create-campaign/add-track`)
                                }}>Start a campaign</button>
                            </div>
                            <h3>Current Tier</h3>
                            <p className='estimateStreamTier'>{calculateStreamsForPrice(Number(budgetNumber)).text}</p>
                        </div>
                        <div className='nextTier'
                        // onClick={() => {
                        //     setBudgetNumber(Number(budgetNumber) + 50)
                        //     setBudgetNumberPercent(((budgetNumber + 50) / (10000 - 50)) * 100)
                        // }}
                        >
                            <div className='tierHover'>
                                <button className='campaign' onClick={() => {
                                    let obj = {
                                        track_name: "",
                                        artist_name: "",
                                        track_link: "",
                                        spotify_link: "",
                                        spotify_uri: "",
                                        track_released: true,
                                        track_lyrics: [],
                                        budget: Number(budgetNumber) + 50,
                                        start_date: dayjs(new Date()).toISOString(),
                                        end_date: "",
                                        campaign_type: "one time",
                                        track_image: "",
                                        userId: "",
                                    }
                                    localStorage.setItem("campaign-data", JSON.stringify(obj));
                                    navigate(`/create-campaign/add-track`)
                                }}>Start a campaign</button>
                            </div>
                            <h3>Next Tier</h3>
                            <p className='estimateStreamTier'>{calculateStreamsForPrice(Number(budgetNumber) + 50).text}</p>
                            <p className='increaseText'>Increase your budget by $50 and get {calculateStreamsForPrice(Number(budgetNumber) + 50).sumOfStreams} additional streams</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Budget