import React from 'react'

function Login() {
  const handleFormSubmit = async () => {
    const domain = "dev-2z4p074m.us.auth0.com";
    const client_id = process.env.REACT_APP_AUTH0;
    const audience = "https://dev-2z4p074m.us.auth0.com/api/v2/";
    const scope = "openid profile email";
    const response_type = "token";
    const redirect_uri = `${window.location.origin}/callback`;
    window.location.replace(
      `https://${domain}/authorize?` +
      `audience=${audience}&` +
      `scope=${scope}&` +
      `response_type=${response_type}&` +
      `client_id=${client_id}&` +
      `redirect_uri=${redirect_uri}`
    );
  };
  return (
    <div className='Login'>
      <div className='innerLogin'>
        <button onClick={handleFormSubmit}>Login</button>
      </div>
    </div>
  )
}

export default Login