import React, { useState } from 'react'
import SongplaceLogo from './../../Assets/Images/songplace.png'
import './landingNavbar.scss'
import { Button, Drawer, Radio, Space } from 'antd';
import { BurgerIcon } from '../../Assets/Icons';

function LandingNavbar() {
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('left');
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const onChange = (e) => {
        setPlacement(e.target.value);
    };
    const handleFormSubmit = async () => {
        const domain = "dev-2z4p074m.us.auth0.com";
        const client_id = process.env.REACT_APP_AUTH0;
        const audience = "https://dev-2z4p074m.us.auth0.com/api/v2/";
        const scope = "openid profile email";
        const response_type = "token";
        const redirect_uri = `${window.location.origin}/callback`;
        window.location.replace(
            `https://${domain}/authorize?` +
            `audience=${audience}&` +
            `scope=${scope}&` +
            `response_type=${response_type}&` +
            `client_id=${client_id}&` +
            `redirect_uri=${redirect_uri}`
        );
    };
    return (
        <div className='LandingNavbar'>
            <div className='innerNavbar'>
                <div className='logoDiv'>
                    <img src={SongplaceLogo} />
                </div>
                <div className='navLinks hideMob'>
                    <div className='link'><a href='#heroSection'>Home</a></div>
                    <div className='link'><a href='#aboutUsSection'>About</a></div>
                    <div className='link'><a href='#featuresSection'>Features</a></div>
                    <div className='link'><a href='#budgetSection'>Pricing</a></div>
                    <div className='link'><a href='#newsLetterSection'>Contact</a></div>
                </div>
                <div className='loginButtn hideMob'>
                    <div className='link' onClick={handleFormSubmit}>
                        Login
                    </div>
                    <button onClick={handleFormSubmit}>
                        Sign up
                    </button>
                </div>
                <button className='burgerIcon' onClick={showDrawer}>
                    <BurgerIcon />
                </button>
            </div>
            <Drawer
                title={false}
                placement={placement}
                closable={false}
                onClose={onClose}
                open={open}
                key={placement}
                className='menuDrawer'

            >
                <div>
                    <div className='logoDiv'>
                        <img src={SongplaceLogo} />
                    </div>
                    <div className='navLinks'>
                        <div className='link'><a href='#heroSection'>Home</a></div>
                        <div className='link'><a href='#aboutUsSection'>About</a></div>
                        <div className='link'><a href='#featuresSection'>Features</a></div>
                        <div className='link'><a href='#budgetSection'>Pricing</a></div>
                        <div className='link'><a href='#newsLetterSection'>Contact</a></div>
                    </div>
                    <div className='loginButtn'>
                        <div className='link'>
                            Login
                        </div>
                        <button>
                            Sign up
                        </button>
                    </div>
                </div>
            </Drawer>
        </div >
    )
}

export default LandingNavbar